<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 咨询名片列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <!--<el-button type="danger" icon="delete" class="handle-del mr10" @click="delAll">批量删除</el-button>-->
                <!--<el-select v-model="select_cate" placeholder="筛选省份" class="handle-select mr10">-->
                    <!--<el-option key="1" label="广东省" value="广东省"></el-option>-->
                    <!--<el-option key="2" label="湖南省" value="湖南省"></el-option>-->
                <!--</el-select>-->
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>
                <el-button type="primary" v-if="isShowSelect" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加咨询名片</el-button>
            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="name" align="center" width="100" label="姓名">
                </el-table-column>
                <el-table-column prop="title" align="center" label="客服姓名">
                </el-table-column>
                <el-table-column prop="b_image" width="120" align="center" label="头像">
                    <template   slot-scope="scope">
                        <el-popover
                                placement="left"
                                title=""
                                width="500"
                                trigger="hover">
                            <img :src="scope.row.b_image" width="150" style="max-width: 100%" />
                            <img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="qrimg" width="120" align="center" label="名片页面二维码">
                    <template   slot-scope="scope">
                        <el-popover
                                placement="left"
                                title=""
                                width="500"
                                trigger="hover">
                            <img :src="scope.row.qrimg" width="150" style="max-width: 100%" />
                            <img slot="reference" :src="scope.row.qrimg" :alt="scope.row.qrimg" style="max-width: 130px; height: auto; max-height: 100px">
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="tel" align="center" width="200" label="电话号码">
                </el-table-column>
                <el-table-column prop="wechat" align="center" width="200" label="微信号">
                </el-table-column>
                <el-table-column prop="fabulous" align="center" width="70" label="获赞数量">
                </el-table-column>
                <!--<el-table-column prop="introduce" width="200" :show-overflow-tooltip='true' label="详细介绍">-->
                <!--</el-table-column>-->
                <el-table-column prop="sort" label="排序" align="center">
                </el-table-column>
                <el-table-column prop="isup" align="center" width="100" label="上架">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isup==1" style="color:green; cursor:pointer; font-size:22px;" @click="isUpSwitch(scope.$index,scope.row)"><i class="el-icon-success"></i></div>
                        <div v-else style="color:red; cursor:pointer; font-size:22px;" @click="isUpSwitch(scope.$index,scope.row)"><i class="el-icon-error"></i></div>
                    </template>
                </el-table-column>
                <el-table-column prop="datetime" label="添加时间" width="170" align="center" sortable>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit"><a :href="scope.row.qrimg" download="qecode.png" target="_blank">下载二维码</a></el-button>
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="70%">
            <el-form ref="form" :rules="rules" :model="form" label-width="100px">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" style="width:400px" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="客服姓名" prop="title">
                    <el-input v-model="form.title" style="width:400px" placeholder="请输入客服姓名"></el-input>
                </el-form-item>
                <el-form-item label="职称" prop="position">
                    <el-input v-model="form.position" style="width:400px" placeholder="请输入职称"></el-input>
                </el-form-item>
                <el-form-item label="头像">
                    <el-upload
                            class="avatar-uploader"
                            name="image"
                            with-credentials
                            list-type="picture-card"
                            :data="{id:this.form.imgid}"
                            :action="uploadUrl()"
                            :on-error="uploadError"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :on-progress="uploading"
                            :show-file-list="false"
                            :auto-upload="true"
                            enctype="multipart/form-data">
                        <img v-if="form.b_image" :src="form.b_image" class="avatar">
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <span style="color:red">建议尺寸900*780</span>
                </el-form-item>
                <el-form-item label="微信二维码">
                    <el-upload
                            class="avatar-uploader"
                            name="image"
                            with-credentials
                            list-type="picture-card"
                            :data="{id:this.form.wid}"
                            :action="uploadUrl()"
                            :on-error="uploadError"
                            :on-success="handleAvatarSuccess2"
                            :before-upload="beforeAvatarUpload"
                            :on-progress="uploading"
                            :show-file-list="false"
                            :auto-upload="true"
                            enctype="multipart/form-data">
                        <img v-if="form.wimage" :src="form.wimage" class="avatar">
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <span style="color:red">建议尺寸800*800</span>
                </el-form-item>
                <el-form-item label="电话号码" prop="tel">
                    <el-input v-model="form.tel" style="width:400px" placeholder="请输入电话号码"></el-input>
                </el-form-item>
                <el-form-item label="微信号" prop="wechat">
                    <el-input v-model="form.wechat" style="width:400px" placeholder="请输入微信号"></el-input>
                </el-form-item>
<!--                <el-form-item label="关联微信">
                    <el-select filterable v-model="form.uid" placeholder="请选择微信昵称">
                        <el-option
                                v-for="item in weChatList"
                                :key="item.id"
                                :label="item.nickname"
                                :value="item.id"
                                :disabled="item.disabled">{{item.nickname}}  <span style="color:red">{{item.temp}}</span>
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="获赞数量">
                    <el-input v-model="form.fabulous" style="width:150px" placeholder="请输入获赞数量"></el-input>
                </el-form-item>
                <el-form-item label="标签" prop="specs">
                    <el-tag
                            :key="tag.id"
                            v-for="tag in form.specs"
                            closable
                            size="medium"
                            :disable-transitions="false"
                            @close="handleClose(tag)">
                        {{tag.title}}
                    </el-tag>
                    <el-input
                            class="input-new-tag"
                            v-if="inputVisible"
                            v-model="inputValue"
                            ref="saveTagInput"
                            size="medium"
                            @keyup.enter.native="handleInputConfirm"
                            @blur="handleInputConfirm"
                    >
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加标签</el-button>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="form.sort" style="width:150px"></el-input>
                    <span style="color:red">&nbsp;&nbsp;注：数值越大展示越靠前，不输入则默认排序</span>
                </el-form-item>
                <el-form-item label="详细介绍" prop="introduce">
                    <el-input type="textarea" style="width:600px;" :rows="6" v-model="form.introduce"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor, Quill} from 'vue-quill-editor'
    import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
    Quill.register('modules/ImageExtend', ImageExtend)
    export default {
        name: 'basetable',
        components: {
            quillEditor
        },
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id: '',
                    name: '',
                    title: '',
                    position: '',
                    introduce: '',
                    tel: '',
                    wechat: '',
                    imgid: '',
                    b_image: '',
                    wid: '',
                    wimage: '',
                    fabulous: '',
                    specs: [],
                    sort: '',
                    uid:'',
                    datetime: ''

                },
                weChatList:[],  //微信用户列表
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                rules: {
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    title: [
                        { required: true, message: '请输入客服姓名', trigger: 'blur' }
                    ],
                    position: [
                        { required: true, message: '请输入职称', trigger: 'blur' }
                    ],
                    introduce: [
                        { required: true, message: '请输入详细介绍', trigger: 'blur' }
                    ],
                    tel: [
                        { required: true, message: '请输入电话号码', trigger: 'blur' }
                    ],
                    wechat:[
                        { required: true, message: '请输入微信号', trigger: 'blur' }
                    ],
                    specs:[
                        { required: true, message: '请添加规格', trigger: 'blur' }
                    ],
                },
                dialogImageUrl: '',
                isShowBigImg: false,
                // 富文本框参数设置
                editorOption: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: 'image',
                            action: this.$api.uploadUrl+"/Images/uploadEditorImage",
                            response: (res) => {
                                return res.data
                            }
                        },
                        toolbar: {
                            container: container,
                            handlers: {
                                'image': function () {
                                    QuillWatch.emit(this.quill.id)
                                }
                            }
                        }
                    }
                },
                //规格
                inputVisible: false,
                inputValue: '',
                loading:false, //加载中
                isShowSelect: false,  //是否展示选择姓名下拉框
            }
        },
        created() {
            this.getData();
            const userInfo=JSON.parse(localStorage.getItem('userInfo'));
            if(userInfo.cid){
                this.isShowSelect=false;
            }else{
                this.isShowSelect=true;
            }
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.title === this.del_list[i].title) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            onEditorChange({ editor, html, text }) {
                this.form.details = html;
            },
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            //删除图片
            handleRemove(file, fileList) {
                // console.log(file.response.data);
                // console.log(file.id);
                let imgid=null;
                if(file.id!=undefined){
                    imgid=file.id
                }else{
                    imgid=file.response.data;
                }
                var params=this.$qs.stringify({
                    imgId: imgid,
                    id: this.form.id
                });
                console.log(params);
                // return;
                this.$api.post('ShopCommodity/delImage', params, res => {
                    var imgArr=this.form.swiperimgTemp;
                    imgArr.forEach(function(item, index, arr) {
                        if(item == imgid) {
                            arr.splice(index, 1);
                        }
                    });
                    this.$message.success(res.msg);
                }, err => {
                    var imgArr=this.form.swiperimgTemp;
                    imgArr.forEach(function(item, index, arr) {
                        if(item == imgid) {
                            arr.splice(index, 1);
                        }
                    });
                    this.$message.error(err.msg);
                });
            },
            //查看大图
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.isShowBigImg = true;
            },
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                this.loading=true;
            },
            //正在上传中
            uploading(event, file, fileList){

            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //图片上传成功
            handleAvatarSuccess(res, file){
                this.loading=false;
                console.log(res);
                this.form.imgid=res.data;
                this.form.b_image = URL.createObjectURL(file.raw);
                this.$message.success(res.msg);
            },
            //微信二维码图片上传成功
            handleAvatarSuccess2(res, file){
                this.loading=false;
                console.log(res);
                this.form.wid=res.data;
                this.form.wimage = URL.createObjectURL(file.raw);
                this.$message.success(res.msg);
            },
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number
                });
                // console.log(params);
                this.$api.post('ShopConsultCationCard/getConsultCationCardList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    console.log(res);
                }, err => {
                    this.tableData = [];
                    this.sumPage = 1*10;
                    this.cur_page = 1;
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
                this.getData();
            },
            formatter(row, column) {
                return row.url;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleEdit(index, row, status) {
                this.getwechatUserList();  //获取微信用户列表
                this.AddOrSave=status;
                //如果是添加则把form清空
                if(status==1){
                    this.form = {
                        id: null,
                        name: null,
                        title: null,
                        position: null,
                        introduce: null,
                        tel: null,
                        wechat: null,
                        imgid: null,
                        b_image: null,
                        wid: null,
                        wimage: null,
                        fabulous: null,
                        specs: [],
                        sort: null,
                        uid: null,
                        datetime: null
                    };
                }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
                    this.form = {
                        id: item.id,
                        name: item.name,
                        title: item.title,
                        position: item.position,
                        introduce: item.introduce,
                        tel: item.tel,
                        wechat: item.wechat,
                        imgid: item.imgid,
                        b_image: item.b_image,
                        wid: item.wid,
                        wimage: item.wimage,
                        fabulous: item.fabulous,
                        specs: item.specs,
                        sort: item.sort,
                        uid: item.uid,
                        datetime: item.datetime
                    };
                }
                this.activeName = "first";  //默认选中第一个Tab
                this.editVisible = true;
                console.log(this.form);
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 保存编辑
            saveEdit(formName) {
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            params=this.$qs.stringify({
                                name: this.form.name,
                                title: this.form.title,
                                position: this.form.position,
                                introduce: this.form.introduce,
                                tel: this.form.tel,
                                wechat: this.form.wechat,
                                imgid: this.form.imgid,
                                wid: this.form.wid,
                                fabulous: this.form.fabulous ? this.form.fabulous : 0,
                                specs: JSON.stringify(this.form.specs),
                                uid: this.form.uid ? this.form.uid : null,
                                sort: this.form.sort ? this.form.sort : 0,
                            });
                        }else{
                            params=this.$qs.stringify({
                                id: this.form.id,
                                name: this.form.name,
                                title: this.form.title,
                                position: this.form.position,
                                introduce: this.form.introduce,
                                tel: this.form.tel,
                                wechat: this.form.wechat,
                                imgid: this.form.imgid,
                                wid: this.form.wid,
                                fabulous: this.form.fabulous ? this.form.fabulous : 0,
                                specs: JSON.stringify(this.form.specs),
                                uid: this.form.uid ? this.form.uid : null,
                                sort: this.form.sort ? this.form.sort : 0,
                            });
                        }
                        // console.log({
                        //     id: this.form.id,
                        //     name: this.form.name,
                        //     title: this.form.title,
                        //     thumbnail: this.form.thumbnail,
                        //     swiperimg: this.form.swiperimgTemp.join(','),
                        //     beforeprice: this.form.beforeprice,
                        //     salenumber: this.form.salenumber,
                        //     categoryid: this.form.categoryid,
                        //     isup: this.form.isup ? 1 : 0,
                        //     hot: this.form.hot ? 1 : 0,
                        //     isrecomd: this.form.isrecomd ? 1 : 0,
                        //     specs: JSON.stringify(this.form.specs),
                        //     sort: this.form.sort,
                        //     details: this.escapeStringHTML(this.form.details),
                        //     isseckill: this.form.isseckill ? 1 : 0,
                        //     seckillprice: this.form.seckillprice,
                        //     startdatetime: this.form.startdatetime,
                        //     enddatetime: this.form.enddatetime,
                        // });
                        // return;
                        this.$api.post('ShopConsultCationCard/saveConsultCationCard', params, res => {
                            this.getData();
                            this.$message.success(res.msg);
                            console.log(res);

                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
                // this.$message.success(`修改第 ${this.idx+1} 行成功`);
            },
            // 确定删除
            deleteRow(){
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                console.log(params);
                // return;
                this.$api.post('ShopConsultCationCard/deleteConsultCationCard', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            //将转移符号替换为html
            escapeStringHTML(str) {
                if(str){
                    str = str.replace(/&lt;/g,'<');
                    str = str.replace(/&gt;/g,'>');
                    str = str.replace(/&quot;/g,'"');
                }
                return str;
            },
            submit(){
                let str=this.escapeStringHTML(this.form.details);
                console.log(str);
            },
            //规格
            handleClose(tag) {
                this.form.specs.splice(this.form.specs.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    let temp=inputValue.split(',');
                    let tempObj={
                        title: temp[0],
                    };
                    this.form.specs.push(tempObj);
                }
                console.log(this.form.specs);
                this.inputVisible = false;
                this.inputValue = '';
            },
            //获取微信用户列表
            getwechatUserList(){
                this.$api.post('ShopConsultCationCard/wechatUserList', null, res => {
                    console.log(res);
                    this.weChatList=res.data;
                }, err => {
                    this.$message.error(err.msg);
                });
            },
            //上下架咨询卡片切换
            isUpSwitch(index, options){
                let _this=this;
                let msg="";
                if(options.isup){
                    msg="确认要下架此咨询嘛？"
                }else{
                    msg="确认要上架此咨询嘛？"
                }
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params=this.$qs.stringify({
                        cardid: options.id,
                        status: !options.isup ? 1 : 0
                    });
                    this.$api.post('ShopConsultCationCard/isUpSwitch', params, res => {
                        _this.tableData[index].isup = !options.isup;
                        if(_this.tableData[index].isup){
                            this.$message({
                                type: 'success',
                                message: '此咨询已上架'
                            });
                        }else{
                            this.$message({
                                type: 'info',
                                message: '此咨询已下架'
                            });
                        }
                    }, err => {
                        console.log(err);
                        this.$message.error(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });
                });


            },

        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 30px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 110px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .ql-snow .ql-editor img{
        vertical-align: top !important;
    }
</style>
